import {FC, useEffect, useState} from 'react'
import {Avatar, Box, Skeleton, Tooltip} from '@mui/material'
import {GenevaUserInterface} from '@/interfaces/user_types'
import {GenevaFileInterface, getProperties} from '@/utils/azureFileProperties'
import {getInitials} from '@/utils/formatter'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import ServerError from '@/views/error/ServerError'
import {alpha} from '@mui/material/styles'

interface UserAvatarAvatarProps {
    application_user: GenevaUserInterface
    height?: number
    width?: number
    disableInitials?: boolean
    toolTip?: string
}

const UserAvatarAvatar: FC<UserAvatarAvatarProps> = ({
    application_user,
    height = 42,
    width = 42,
    disableInitials = false,
    toolTip,
}) => {
    const [fileDisplay, setFileDisplay] = useState<GenevaFileInterface>()
    const [localToolTip, setLocalToolTip] = useState<string>(toolTip || application_user.description)

    useEffect(() => {
        if (application_user.avatar_data_file_url && application_user.avatar_data_file_container_name) {
            getProperties(
                application_user.avatar_data_file_url,
                application_user.avatar_data_file_container_name,
                true,
            )?.then((res) => {
                setFileDisplay(res)
            })
        }
    }, [application_user])

    useEffect(() => {
        if (toolTip) {
            setLocalToolTip(toolTip)
        }
    }, [toolTip])

    const emptyAvatar = () => {
        return (
            <Tooltip title={localToolTip}>
                <Avatar
                    sx={{
                        height: height,
                        width: width,
                        backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
                        color: 'success.main',
                    }}
                >
                    {!disableInitials ? getInitials(`${application_user.full_name}`) : ''}
                </Avatar>
            </Tooltip>
        )
    }

    return (
        <>
            {fileDisplay ? (
                <Box>
                    {fileDisplay.displayType === 'error' ? (
                        emptyAvatar()
                    ) : (
                        <Tooltip title={localToolTip}>
                            <Avatar
                                alt={application_user.description}
                                sx={{height: height, width: width}}
                                src={fileDisplay.fileName}
                            />
                        </Tooltip>
                    )}
                </Box>
            ) : (
                emptyAvatar()
            )}
        </>
    )
}

interface UserAvatarProps {
    application_user_id: number
    height?: number
    width?: number
    color?: string
    disableInitials?: boolean
    isShowReportsTo?: boolean
}

const UserAvatar: FC<UserAvatarProps> = ({
    application_user_id,
    height = 42,
    width = 42,
    disableInitials = false,
    isShowReportsTo = false,
}) => {
    const [toolTip, setToolTip] = useState<string>('')
    const {data, isLoading, error} = useGenevaFetch(`/api/users/${application_user_id}`)

    if (error) {
        return <ServerError />
    }

    useEffect(() => {
        if (data && data.application_user) {
            if (isShowReportsTo) {
                setToolTip(`Reports To ${data.application_user.reports_to_application_user_full_name}`)
            } else {
                setToolTip(data.application_user.description)
            }
        }
    }, [isShowReportsTo, data])

    return !isLoading ? (
        <UserAvatarAvatar
            application_user={data.application_user}
            height={height}
            width={width}
            disableInitials={disableInitials}
            toolTip={toolTip}
        />
    ) : (
        <Skeleton>
            <Avatar
                sx={{
                    height: height,
                    width: width,
                    backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
                    color: 'success.main',
                }}
            />
        </Skeleton>
    )
}

export default UserAvatar
