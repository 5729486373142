import {FC} from 'react'
import GenevaNextLink from '@/components/widgets/Layout/GenevaNextLink'
import type {AppBarProps, Theme} from '@mui/material'
import {Alert, AppBar, Box, IconButton, Toolbar, Tooltip, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import DashboardNavbarAccount from './DashboardNavbarAccount'
import ContentSearch from './ContentSearch'
import Logo from '@/components/widgets/Logo'
import DashboardNavbarTasks from './DashboardNavbarTasks'
import DashboardNavbarNotifications from './DashboardNavbarNotifications'
import DashboardNavbarNewNote from '@/layouts/ProviderDashboardLayout/DashboardNavbarNewNote'
import DateRangeIcon from '@mui/icons-material/DateRange'
import {useRouter} from 'next/router'
import useGenevaUser from '@/hooks/useGenevaUser'
import DashboardNavbarUserWarning from '@/layouts/ProviderDashboardLayout/DashboardNavbarUserWarning'
import useMediaQuery from '@mui/material/useMediaQuery'

interface DashboardNavbarProps extends AppBarProps {
    onSidebarMobileOpen?: () => void
}

interface DevTheme extends Theme {
    devTheme: boolean
}

const DashboardNavbarRoot = styled(AppBar)(({theme}) => {
    //@ts-ignore
    const devTheme = theme as DevTheme
    return {
        //@ts-ignore
        ...((devTheme.palette.mode === 'light' || devTheme.devTheme) && {
            backgroundColor: devTheme.palette.primary.main,
            boxShadow: 'none',
            color: devTheme.palette.primary.contrastText,
        }),
        ...(devTheme.palette.mode === 'dark' &&
            !devTheme.devTheme && {
                backgroundColor: devTheme.palette.background.paper,
                borderBottom: `1px solid ${devTheme.palette.divider}`,
                boxShadow: 'none',
            }),
        //@ts-ignore
        zIndex: devTheme.zIndex.drawer + 100,
    }
})

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
    const router = useRouter()
    const {genevaUser} = useGenevaUser()
    const {onSidebarMobileOpen, ...other} = props

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

    const handleGoToAgenda = async () => {
        if (genevaUser) {
            await router.push(`/user/${genevaUser.id}/agenda`)
        }
    }

    return (
        <DashboardNavbarRoot {...other} id={'dashboardNavbarRoot'}>
            <Toolbar sx={{minHeight: 64}}>
                {lgUp ? null : (
                    <IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
                        <MenuIcon fontSize="small" />
                    </IconButton>
                )}
                {lgDown ? null : (
                    <GenevaNextLink href={'/'} passHref>
                        <Logo sx={{height: 40, width: 40}} />
                    </GenevaNextLink>
                )}
                {!process.env.NEXT_PUBLIC_ENVIRONMENT ||
                (process.env.NEXT_PUBLIC_ENVIRONMENT && process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') ? (
                    <Box sx={{flexGrow: 1, ml: 2}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Tooltip
                                title={
                                    process.env.NEXT_PUBLIC_ENVIRONMENT
                                        ? 'This is a non-production instance of Irys'
                                        : 'No NEXT_PUBLIC_ENVIRONMENT variable in milan/.env set'
                                }
                            >
                                <Alert
                                    severity={process.env.NEXT_PUBLIC_ENVIRONMENT ? 'info' : 'warning'}
                                    sx={{alignItems: 'center'}}
                                >
                                    <Box sx={{ml: 2, mr: 2}}>
                                        <Typography>
                                            {`${
                                                process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT &&
                                                process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT !==
                                                    process.env.NEXT_PUBLIC_ENVIRONMENT
                                                    ? process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT.toUpperCase()
                                                    : ''
                                            }
                                              ${
                                                  process.env.NEXT_PUBLIC_ENVIRONMENT
                                                      ? process.env.NEXT_PUBLIC_ENVIRONMENT.toUpperCase()
                                                      : 'UNKNOWN'
                                              }
                                                ENVIRONMENT`}
                                        </Typography>
                                    </Box>
                                </Alert>
                            </Tooltip>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{flexGrow: 0.4, ml: 2}} />
                )}
                <Box sx={{display: 'flex', flexGrow: 1, justifyContent: 'center', minWidth: '600px'}}>
                    <ContentSearch />
                </Box>
                <Box sx={{flexGrow: 1}} />
                <Box sx={{ml: 1}}>
                    <DashboardNavbarUserWarning />
                </Box>
                <Box sx={{ml: 1}}>
                    <DashboardNavbarNewNote />
                </Box>
                <Box sx={{ml: 1}}>
                    <Tooltip title="Agenda">
                        <IconButton color="inherit" onClick={handleGoToAgenda} size="large">
                            <DateRangeIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ml: 1}}>
                    <DashboardNavbarNotifications />
                </Box>
                <Box sx={{ml: 1}}>
                    <DashboardNavbarTasks />
                </Box>
                <Box sx={{ml: 2}}>
                    <DashboardNavbarAccount />
                </Box>
            </Toolbar>
        </DashboardNavbarRoot>
    )
}

export default DashboardNavbar
