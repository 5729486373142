import {FC, ReactNode} from 'react'
import type {ListProps} from '@mui/material'
import {List, ListSubheader} from '@mui/material'
import NavItem from './NavItem'
import {useRouter} from 'next/router'

interface Item {
    hrefPathname?: string
    hrefQuery?: string
    icon?: ReactNode
    info?: ReactNode
    children?: Item[]
    title: string
}

interface NavSectionProps extends ListProps {
    items: Item[]
    pathname: string
    title: string
}

const renderNavItems = ({
    depth = 0,
    items,
    pathname,
}: {
    items: Item[]
    pathname: string
    depth?: number
}): JSX.Element => (
    <List disablePadding>
        {items.reduce(
            (acc: JSX.Element[], item: Item) =>
                reduceChildRoutes({
                    acc,
                    item,
                    pathname,
                    depth,
                }),
            [],
        )}
    </List>
)

function matchPath(actPathname: string, hrefPathname: string) {
    return actPathname === hrefPathname
}

const reduceChildRoutes = ({
    acc,
    pathname,
    item,
    depth,
}: {
    acc: JSX.Element[]
    pathname: string
    item: Item
    depth: number
}): Array<JSX.Element> => {
    const router = useRouter()
    const key = `${item.title}-${depth}`
    const exactMatch = item.hrefPathname ? matchPath(item.hrefPathname, pathname) : false

    if (item.children) {
        const partialMatch = item.hrefPathname ? matchPath(item.hrefPathname, router.asPath) : false
        const childMatch = item.children.some((child) => child.hrefPathname == router.asPath)

        acc.push(
            <NavItem
                active={exactMatch}
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={partialMatch || childMatch}
                hrefPathname={item.hrefPathname}
                hrefQuery={item.hrefQuery}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    items: item.children,
                    pathname,
                })}
            </NavItem>,
        )
    } else {
        acc.push(
            <NavItem
                active={exactMatch}
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                hrefPathname={item.hrefPathname}
                hrefQuery={item.hrefQuery}
                title={item.title}
            />,
        )
    }

    return acc
}

const NavSection: FC<NavSectionProps> = (props) => {
    const {items, pathname, title, ...other} = props

    return (
        <List
            subheader={
                <ListSubheader
                    disableGutters
                    disableSticky
                    sx={{
                        color: 'text.primary',
                        fontSize: '0.75rem',
                        lineHeight: 2.5,
                        fontWeight: 700,
                        textTransform: 'uppercase',
                    }}
                >
                    {title}
                </ListSubheader>
            }
            {...other}
        >
            {renderNavItems({
                items,
                pathname,
            })}
        </List>
    )
}

export default NavSection
