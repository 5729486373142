import {FC, useRef, useState} from 'react'
import {IconButton, Tooltip} from '@mui/material'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import useGenevaUser from '@/hooks/useGenevaUser'
import dynamic from 'next/dynamic'

const DashboardNavbarNewNotePopover = dynamic(
    () => import('@/layouts/ProviderDashboardLayout/DashboardNavbarNewNotePopover'),
    {ssr: false},
)

const DashboardNavbarNewNote: FC = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const [open, setOpen] = useState<boolean>(false)

    const {isGenevaLoading} = useGenevaUser()

    if (isGenevaLoading) {
        return null
    }

    const handleOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Add Note">
                <IconButton color="inherit" onClick={handleOpen} ref={anchorRef} size="large">
                    <SpeakerNotesIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            {open && <DashboardNavbarNewNotePopover anchorRef={anchorRef} open={open} handleClose={handleClose} />}
        </>
    )
}

export default DashboardNavbarNewNote
