import {ReactNode} from 'react'
import Grid from '@mui/material/Grid2'
import {Alert, Box, Button, Tooltip, Typography} from '@mui/material'

interface WarningAlertsProps<T> {
    warnings: T[]
    onClick?: () => void
    onWarningClick?: (warning: WarningInterface) => void
    isWarningClickable?: (warning: WarningInterface) => boolean
    isTwilio?: boolean
    isStacked?: boolean
}

export const getAlertSeverity = (warning: WarningInterface) => {
    if (warning.is_urgent_tier) {
        return 'error'
    } else if (warning.is_minor_tier) {
        return 'warning'
    } else if (warning.is_info_tier) {
        return 'info'
    }
}

export interface WarningInterface {
    message: string
    further_info: string
    is_urgent_tier: boolean
    is_minor_tier: boolean
    is_info_tier: boolean

    [key: string]: any
}

const WarningAlertsItem = <T extends WarningInterface>(props: WarningAlertsProps<T> & {children?: ReactNode}) => {
    const {warnings, onClick, onWarningClick, isWarningClickable, isTwilio, isStacked} = props

    return (
        <Grid sx={{m: isTwilio ? 1 : 0, mb: 0}} size={isTwilio || isStacked ? 12 : 4}>
            <Box>
                <Alert
                    sx={{alignItems: 'center', py: isTwilio ? 0 : 0.75}}
                    severity={getAlertSeverity(warnings[0])}
                    action={
                        onClick && (
                            <Button color="inherit" size="small" onClick={onClick}>
                                Details
                            </Button>
                        )
                    }
                >
                    {warnings.map((warning, index) => {
                        const clickable = onWarningClick && isWarningClickable && isWarningClickable(warning)

                        return (
                            <Tooltip
                                key={`Warning_${getAlertSeverity(warning)}_${index}`}
                                title={warning.further_info}
                                placement="top"
                            >
                                <Box sx={{cursor: clickable ? 'pointer' : 'cursor'}}>
                                    <Typography
                                        onClick={() => clickable && onWarningClick && onWarningClick(warning)}
                                        sx={{textDecoration: clickable ? 'underline' : undefined}}
                                        variant="h6"
                                    >
                                        {warning.message}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        )
                    })}
                </Alert>
            </Box>
        </Grid>
    )
}

const WarningAlerts = <T extends WarningInterface>(props: WarningAlertsProps<T> & {children?: ReactNode}) => {
    const {warnings, onClick, onWarningClick, isWarningClickable, isTwilio = false, isStacked = false} = props

    const urgentWarnings = warnings.filter((warning) => warning.is_urgent_tier)
    const minorWarnings = warnings.filter((warning) => warning.is_minor_tier)
    const infoWarnings = warnings.filter((warning) => warning.is_info_tier)

    return (
        <Grid container spacing={3} sx={{'.MuiGrid2-root': {pt: isTwilio ? 0 : 3}, mt: isTwilio ? 0 : -3}}>
            {urgentWarnings.length > 0 && (
                <WarningAlertsItem
                    warnings={urgentWarnings}
                    onClick={onClick}
                    onWarningClick={onWarningClick}
                    isWarningClickable={isWarningClickable}
                    isTwilio={isTwilio}
                    isStacked={isStacked}
                />
            )}
            {minorWarnings.length > 0 && (
                <WarningAlertsItem
                    warnings={minorWarnings}
                    onClick={onClick}
                    onWarningClick={onWarningClick}
                    isWarningClickable={isWarningClickable}
                    isTwilio={isTwilio}
                    isStacked={isStacked}
                />
            )}
            {infoWarnings.length > 0 && (
                <WarningAlertsItem
                    warnings={infoWarnings}
                    onClick={onClick}
                    onWarningClick={onWarningClick}
                    isWarningClickable={isWarningClickable}
                    isTwilio={isTwilio}
                    isStacked={isStacked}
                />
            )}
        </Grid>
    )
}

export default WarningAlerts
