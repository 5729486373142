import {AxiosResponse} from 'axios'
import authAxios, {setGenevaAuthToken} from '@/lib/api/geneva/authAxios'

export class FetcherError extends Error {
    public date: Date
    public data: any
    public response: AxiosResponse | undefined
    public request: any
    public status: number | undefined
    public headers: number | undefined
    public url: string

    constructor(url: string, ...params: any) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params)

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FetcherError)
        }

        this.url = url
        this.name = 'FetcherError'
        this.date = new Date()
    }
}

export class ResponseError extends FetcherError {}

export class RequestError extends FetcherError {}

export class SetupError extends FetcherError {}

export const genevaFetchWithToken = (url: string, genevaAuthToken: string, params?: any) => {
    setGenevaAuthToken(genevaAuthToken)
    return authAxios
        .get(url, {params: params})
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            if (error.response || error.error?.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                let response = error.response
                if (!error.response && error.error?.response) {
                    response = error.error.response
                }
                const fetcherError = new ResponseError(url, `1. An error occurred while fetching the data`)
                // Attach extra info to the error object.
                fetcherError.response = response
                fetcherError.data = response.data
                fetcherError.status = response.status
                fetcherError.headers = response.headers
                fetcherError.message = response.data.message
                throw fetcherError
            }
            if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.error('2. FetcherError', {error})
                const fetcherError = new RequestError(url, '2. An error occurred while fetching the data')
                fetcherError.request = error.request
                throw fetcherError
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('3. FetcherError', {error})
                const fetcherError = new SetupError(url, '3. An error occurred while fetching the data')
                fetcherError.message = error.message
                throw fetcherError
            }
        })
}
