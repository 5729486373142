import {FC} from 'react'
import {Box, Paper} from '@mui/material'
import SupplierPersonSearchAutocomplete from '@/components/search/SupplierPersonSearchAutocomplete'

const ContentSearch: FC = () => {
    return (
        <Paper sx={{alignItems: 'center', display: 'flex', flexGrow: 1}}>
            <Box sx={{alignItems: 'center', display: 'flex', flexGrow: 1}}>
                <SupplierPersonSearchAutocomplete />
            </Box>
        </Paper>
    )
}

export default ContentSearch
