import {FC, useEffect} from 'react'
import {Box, Divider, Drawer, Link, Typography} from '@mui/material'
import useGenevaUser from '../../hooks/useGenevaUser'
import Logo from '@/components/widgets/Logo'
import NavSection from '@/components/widgets/Layout/NavSection'
import {SectionInterface} from '@/interfaces/layout_types'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Theme} from '@mui/system'

interface DashboardSidebarProps {
    sections: SectionInterface[]
    onMobileClose: () => void
    openMobile: boolean
    dashboardNavbarRootHeight: number
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const {onMobileClose, openMobile, dashboardNavbarRootHeight} = props
    const {genevaUser} = useGenevaUser()

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

    if (!genevaUser) {
        return null
    }

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose()
        }
    }, [location.pathname])

    const content = (
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            {lgUp ? null : (
                <Box sx={{display: 'flex', justifyContent: 'center', p: 2}}>
                    <Logo sx={{height: 40, width: 40}} />
                </Box>
            )}
            <Divider />
            <Box sx={{p: 2}}>
                {props.sections.map((section) => (
                    <NavSection key={section.title} pathname={location.pathname} sx={{'& + &': {mt: 3}}} {...section} />
                ))}
            </Box>
            <Divider />
            <Box sx={{p: 2}}>
                <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                    Need Help?
                </Typography>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    <Link
                        target="_blank"
                        href={'https://irys-solutions.atlassian.net/wiki/spaces/Brussels/overview'}
                        underline="hover"
                    >
                        Check our docs
                    </Link>
                </Typography>
            </Box>
        </Box>
    )

    return (
        <>
            {lgUp ? null : (
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    PaperProps={{sx: {backgroundColor: 'background.paper', width: 280}}}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            )}
            {lgDown ? null : (
                <Drawer
                    anchor="left"
                    open
                    PaperProps={{
                        sx: {
                            backgroundColor: 'background.paper',
                            height: `calc(100% - ${dashboardNavbarRootHeight}px) !important`,
                            top: `${dashboardNavbarRootHeight}px !Important`,
                            width: 280,
                        },
                    }}
                    variant="persistent"
                >
                    {content}
                </Drawer>
            )}
        </>
    )
}

export default DashboardSidebar
