import GroupIcon from '@mui/icons-material/Group'
import {ItemInterface} from '@/interfaces/layout_types'
import BusinessIcon from '@mui/icons-material/Business'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import CampaignIcon from '@mui/icons-material/Campaign'
import {GenevaUserInterface} from '@/interfaces/user_types'

export function topAdminSection(): ItemInterface {
    return {
        title: 'Admin',
        icon: <GroupIcon />,
        hrefPathname: '/admin/users',
    }
}

export function topProviderSection(): ItemInterface {
    return {
        title: 'Provider',
        icon: <BusinessIcon />,
        hrefPathname: `/provider`,
    }
}

export function topFinanceSection(): ItemInterface {
    return {
        title: 'Finance',
        icon: <MonetizationOnIcon />,
        hrefPathname: `/finance/supplier_payments/invoice_search`,
    }
}

export function topSupplierSection(): ItemInterface {
    return {
        title: 'Suppliers',
        icon: <BusinessIcon />,
        hrefPathname: `/suppliers`,
    }
}

export function topMyClientsSection(genevaUser: GenevaUserInterface | null): ItemInterface {
    return {
        title: 'My Clients',
        icon: <EmojiPeopleIcon />,
        hrefPathname:
            genevaUser &&
            (genevaUser.is_care_manager ||
                genevaUser.is_senior_care_manager ||
                genevaUser.is_team_leader ||
                genevaUser.is_operational_admin)
                ? `/my_clients/my_care_management`
                : `/my_clients`,
    }
}

export function topSalesSection(genevaUser: GenevaUserInterface | null): ItemInterface {
    return {
        title: 'Sales',
        icon: <AccessibilityNewIcon />,
        hrefPathname:
            genevaUser &&
            (genevaUser.is_home_care_specialist ||
                genevaUser.is_home_care_specialist_field ||
                genevaUser.is_manager ||
                genevaUser.is_admin)
                ? `/sales/my_leads`
                : `/sales/leads`,
    }
}

export function topMarketingSection(): ItemInterface {
    return {
        title: 'Marketing',
        icon: <CampaignIcon />,
        hrefPathname: `/marketing/marketing_dashboard`,
    }
}

export function topBookingsSection(genevaUser: GenevaUserInterface | null): ItemInterface {
    const url = genevaUser && genevaUser.is_service_coordinator ? '/bookings' : `/bookings`
    return {
        title: 'Bookings',
        icon: <EventAvailableIcon />,
        hrefPathname: url,
    }
}
