import {useEffect, useMemo, useRef, useState} from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import SearchIcon from '@mui/icons-material/Search'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import {CircularProgress, InputAdornment, Link, ListItem} from '@mui/material'
import Box from '@mui/material/Box'
import {SearchResultInterface} from '@/interfaces/search_types'
import {searchEntityDescription} from '@/lib/api/geneva/search'
import {getLatestSearchResults, saveApplicationUserSearchResult} from '@/lib/api/geneva/application_user'
import {useRouter} from 'next/router'
import {ApplicationUserSearchResult} from '@/interfaces/user_types'
import axios from 'axios'
import {throttle} from 'lodash'

export interface NewApplicationUserSearchResultInterface {
    user_id?: number
    person_id?: number
    lead_id?: number
    supplier_id?: number
}

export default function SupplierPersonSearchAutocomplete() {
    const router = useRouter()
    const [isNavigating, setIsNavigating] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>('')
    const [options, setOptions] = useState<SearchResultInterface[]>([])
    const [previousAbort, setPreviousAbort] = useState<AbortController>()
    const [localLatestResults, setLocalLatestResults] = useState<ApplicationUserSearchResult[]>([])
    const highlightedResult = useRef<SearchResultInterface>()

    const getLatestResults = async () => {
        const {data} = await getLatestSearchResults()
        setLocalLatestResults(data.application_user_search_results)
    }

    const getSearchPredictions = async (searchString: string, callback: (results: SearchResultInterface[]) => void) => {
        try {
            const newAbort = new AbortController()
            setPreviousAbort(newAbort)
            const {data} = await searchEntityDescription(searchString, newAbort)
            return callback(data.results)
        } catch (err: any) {
            if (!axios.isCancel(err.error)) {
                console.error({err})
            }
        }
    }

    const fetch = useMemo(
        () =>
            throttle((searchString: string, callback: (results: SearchResultInterface[]) => void) => {
                getSearchPredictions(searchString, callback)
            }, 500),
        [],
    )

    const handleSearchResultClick = async (searchResult: SearchResultInterface) => {
        setIsNavigating(true)
        await router.push(getHref(searchResult))
        setInputValue(searchResult.description)
        let newApplicationUserSearchResult: NewApplicationUserSearchResultInterface = {}
        newApplicationUserSearchResult.supplier_id = searchResult.supplier_id
        newApplicationUserSearchResult.person_id = searchResult.person_id
        newApplicationUserSearchResult.user_id = searchResult.user_id
        newApplicationUserSearchResult.lead_id = searchResult.lead_id
        await saveApplicationUserSearchResult(newApplicationUserSearchResult)
        setIsNavigating(false)
    }

    useEffect(() => {
        if (inputValue.length <= 1 || inputValue === '') {
            setOptions(localLatestResults)
        } else {
            if (previousAbort) {
                previousAbort.abort()
            }
            fetch(inputValue, (results: SearchResultInterface[]) => {
                setOptions(results)
                setPreviousAbort(undefined)
            })
        }
    }, [inputValue, localLatestResults])

    const getKey = (result: SearchResultInterface | ApplicationUserSearchResult) => {
        let keys = []
        if ('id' in result && result.id) {
            keys.push(`ID${result.id}`)
        }
        if (result.user_id) {
            keys.push(`USER_${result.user_id}`)
        }
        if (result.person_id) {
            keys.push(`PERSON_${result.person_id}`)
        }
        if (result.supplier_id) {
            keys.push(`SUPPLIER_${result.supplier_id}`)
        }
        if (result.lead_id) {
            keys.push(`LEAD_${result.lead_id}`)
        }
        return keys.join('_')
    }

    const getHref = (option: SearchResultInterface) => {
        if (option.is_supplier) {
            return `/supplier/${option.supplier_id}`
        } else if (option.is_worker) {
            return `/supplier/${option.supplier_id}/workers/${option.person_id}`
        } else if (option.is_client) {
            return `/client/${option.person_id}`
        } else if (option.is_provider_worker) {
            return `/user/${option.user_id}/profile`
        } else if (option.is_person) {
            return `/person/${option.person_id}`
        } else if (option.is_lead) {
            return `/sales/lead/${option.lead_id}`
        } else {
            return ''
        }
    }

    const getIcon = (option: SearchResultInterface) => {
        if (option.is_supplier) {
            return <EmojiTransportationIcon />
        } else if (option.is_person) {
            return <EmojiPeopleIcon />
        } else if (option.is_lead) {
            return <AccessibilityNewIcon />
        } else {
            return undefined
        }
    }

    return (
        <Autocomplete
            id="search-person-description"
            fullWidth
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            inputValue={inputValue}
            sx={{'& .MuiOutlinedInput-root': {pt: '0px', pb: '0px'}}}
            onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            onFocus={() => getLatestResults()}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{color: 'inherit'}}
                    fullWidth
                    placeholder="Search …"
                    onKeyDown={async (event) => {
                        if (event.key == 'Enter' && highlightedResult.current) {
                            await handleSearchResultClick(highlightedResult.current)
                        }
                    }}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            startAdornment: isNavigating ? (
                                <InputAdornment position="start">
                                    <CircularProgress size={24} />
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            type: 'search',
                        },
                    }}
                />
            )}
            onHighlightChange={(_event, option) => {
                if (option) {
                    highlightedResult.current = option
                }
            }}
            renderOption={(props, result) => {
                return (
                    <ListItem {...props} key={getKey(result)} onClick={() => handleSearchResultClick(result)}>
                        <Box sx={{mb: 2}}>
                            {getIcon(result)}
                            <Link variant="subtitle2" underline="hover" sx={{color: 'text.primary'}}>
                                {result.description}
                            </Link>
                        </Box>
                    </ListItem>
                )
            }}
        />
    )
}
