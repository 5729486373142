import {FC} from 'react'
import {Avatar} from '@mui/material'

interface LogoProps {
    [key: string]: any
}

const Logo: FC<LogoProps> = (props) => {
    return <Avatar alt={'Logo'} src={'/mstile-150x150.png'} {...props} />
}

export default Logo
