import {ContainerClient} from '@azure/storage-blob'
import {v4 as uuidv4} from 'uuid'
import {saveAs} from 'file-saver'
import axios from 'axios'
import {StatusCodes} from 'http-status-codes'

export interface GenevaFileInterface {
    displayType: 'textIcon' | 'azureImage' | 'error'
    fileName: string | undefined
    altFileName: string | undefined
    fileType: string | undefined
}

export interface GenevaCachedFileInterface extends GenevaFileInterface {
    lastAccess: Date
}

const blobToBase64 = (blob: Blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise<string | null>((res) => {
        reader.onloadend = () => {
            let result = reader.result
            if (!result) {
                res(result)
            } else {
                if (typeof result != 'string') {
                    result = new TextDecoder('utf-8').decode(result)
                }
                res(result.replace('data:application/octet-stream', 'data:application/pdf'))
            }
        }
    })
}

export const getProperties = (azure_url: string | undefined, containerID: string, isUseCache: boolean = false) => {
    if (azure_url) {
        if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(azure_url)) {
            if (isUseCache) {
                const value = checkCache(azure_url, containerID)
                if (value) {
                    return new Promise<GenevaFileInterface>((resolve, _reject) => {
                        resolve(value)
                    })
                }
            }
            return axios
                .post('/api/file_properties', {
                    azure_url: azure_url,
                    containerID: containerID,
                })
                .then(async (res) => {
                    let value: GenevaFileInterface
                    if (res.data.imageURL) {
                        const base64 = await fetch(res.data.imageURL)
                            .then((response) => response.blob())
                            .then(blobToBase64)
                        value = {
                            displayType: 'azureImage',
                            fileName: base64,
                            altFileName: res.data.fileProperties.metadata.original_file_name,
                            fileType: res.data.fileProperties.contentType,
                        } as GenevaFileInterface
                    } else {
                        value = {
                            displayType: 'textIcon',
                            fileName: res.data.fileProperties.metadata.original_file_name,
                            fileType: res.data.fileProperties.contentType,
                        } as GenevaFileInterface
                    }
                    if (isUseCache) {
                        await setCache(azure_url, containerID, value, 1)
                    }
                    return value
                })
                .catch(async (error) => {
                    const {captureException} = await import('@sentry/nextjs')
                    captureException(error)
                    console.error(error)
                    return {displayType: 'error'} as GenevaFileInterface
                })
        }
        return null
    }
}

const checkCache = (azure_url: string, containerID: string) => {
    const key = `geneva_cache_${containerID}_${azure_url}`
    const cacheValue = localStorage.getItem(key)
    if (cacheValue) {
        let updatedCacheValue = JSON.parse(cacheValue) as GenevaCachedFileInterface
        updatedCacheValue.lastAccess = new Date()
        localStorage.setItem(key, JSON.stringify(updatedCacheValue))
        return updatedCacheValue
    } else {
        return null
    }
}

const setCache = async (azure_url: string, containerID: string, value: GenevaFileInterface, attempt: number) => {
    const key = `geneva_cache_${containerID}_${azure_url}`
    let newCacheValue: GenevaCachedFileInterface = {...value, lastAccess: new Date()}
    if (newCacheValue.fileName) {
        newCacheValue.fileName = await resizeImage(newCacheValue.fileName, 84, 84)
    }
    try {
        localStorage.setItem(key, JSON.stringify(newCacheValue))
    } catch (err) {
        if (isQuotaExceededError(err)) {
            if (attempt <= 3) {
                attempt += 1
                evictOldestCacheItem()
                await setCache(azure_url, containerID, value, attempt)
            } else {
                console.error('Failed to cache new value after 3 attempts')
            }
        } else {
            console.error(err)
        }
    }
}

const evictOldestCacheItem = () => {
    let oldestDate = new Date()
    let oldestKey: string | undefined = undefined
    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('geneva_cache_')) {
            const cacheValue = localStorage.getItem(key)
            if (cacheValue) {
                const value = JSON.parse(cacheValue)
                if (value.hasOwnProperty('lastAccess') && value.lastAccess && new Date(value.lastAccess) < oldestDate) {
                    oldestDate = value.lastAccess
                    oldestKey = key
                }
            }
        }
    })
    if (oldestKey) {
        localStorage.removeItem(oldestKey)
    }
}

const isQuotaExceededError = (err: unknown) => {
    return err instanceof Error && (err.name === 'QuotaExceededError' || err.name === 'NS_ERROR_DOM_QUOTA_REACHED')
}

const resizeImage = (base64: string, maxWidth: number, maxHeight: number) => {
    return new Promise<string>((resolve) => {
        let img = new Image()
        img.src = base64
        img.onload = () => {
            let canvas = document.createElement('canvas')
            let width = img.width
            let height = img.height

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width
                    width = maxWidth
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height
                    height = maxHeight
                }
            }
            canvas.width = width
            canvas.height = height
            let ctx = canvas.getContext('2d')
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height)
            }
            resolve(canvas.toDataURL())
        }
    })
}

export const uploadFiles = async (file: File | null, containerID: string) => {
    if (!file) return []

    try {
        const response = await axios.post('/api/file_upload', {containerID: containerID})
        const {BlobServiceClient} = await import('@azure/storage-blob')
        const blobServiceClient = new BlobServiceClient(
            `https://${response.data.account}.blob.core.windows.net?${response.data.token}`,
        )
        const containerClient = blobServiceClient.getContainerClient(containerID)
        return await createBlobInContainer(containerClient, file)
    } catch (error: any) {
        const sentryErrors = [StatusCodes.NOT_FOUND, StatusCodes.INTERNAL_SERVER_ERROR]
        if (error.response && error.response.status && sentryErrors.includes(error.response.status)) {
            const name = error.name
            const data = error.response?.data
            const message = error.response?.message
            const {withScope, captureException} = await import('@sentry/nextjs')
            withScope((scope) => {
                scope.setExtra('error', error)
                name && scope.setExtra('error.name', name)
                data && scope.setExtra('error.response.data', {data})
                message && scope.setExtra('error.response.message', {message})
                captureException(error)
            })
        }
        return error
    }
}

const createBlobInContainer = async (containerClient: ContainerClient, file: File) => {
    const blobName = uuidv4()
    const blockBlobClient = containerClient.getBlockBlobClient(blobName)
    Object.defineProperty(file, 'name', {
        writable: true,
        value: file.name.replace(/[^\u0000-\u00ff]/g, ''),
    })
    return await blockBlobClient
        .uploadData(file, {
            blobHTTPHeaders: {blobContentType: file.type},
            metadata: {original_file_name: file.name},
        })
        .then(() => {
            return blobName
        })
        .catch(async (error) => {
            const {captureException} = await import('@sentry/nextjs')
            captureException(error)
            return error
        })
}

export const downloadFile = async (azure_url: string, containerID: string, returnBlobBody = false) => {
    if (azure_url) {
        try {
            const response = await axios.post('/api/file_download', {
                containerID: containerID,
                azure_url: azure_url,
            })
            const {BlobServiceClient} = await import('@azure/storage-blob')
            const blobServiceClient = new BlobServiceClient(
                `https://${response.data.account}.blob.core.windows.net?${response.data.token}`,
            )
            const containerClient = blobServiceClient.getContainerClient(containerID)
            const blobClient = containerClient.getBlobClient(azure_url)
            const downloadBlob = await blobClient.download()
            if (returnBlobBody) {
                const blobBody = await downloadBlob.blobBody
                if (blobBody) {
                    return new Blob([blobBody])
                }
            }
            await blobToFile(await downloadBlob.blobBody, response.data.fileName)
        } catch (error: any) {
            const sentryErrors = [StatusCodes.NOT_FOUND, StatusCodes.INTERNAL_SERVER_ERROR]
            if (error.response && error.response.status && sentryErrors.includes(error.response.status)) {
                const name = error.name
                const data = error.response?.data
                const message = error.response?.message
                const {withScope, captureException} = await import('@sentry/nextjs')
                withScope((scope) => {
                    scope.setExtra('error', error)
                    name && scope.setExtra('error.name', name)
                    data && scope.setExtra('error.response.data', {data})
                    message && scope.setExtra('error.response.message', {message})
                    captureException(error)
                })
            }
            return error
        }
    }
}

async function streamToString(readableStream: NodeJS.ReadableStream): Promise<string> {
    return new Promise((resolve, reject) => {
        const chunks: Buffer[] = []
        readableStream.on('data', (data) => {
            chunks.push(data instanceof Buffer ? data : Buffer.from(data))
        })
        readableStream.on('end', () => {
            resolve(Buffer.concat(chunks).toString('utf-8'))
        })
        readableStream.on('error', reject)
    })
}

export async function downloadBlobContent(containerID: string, azure_url: string): Promise<string | undefined> {
    if (azure_url) {
        try {
            const response = await axios.post('/api/file_download', {
                containerID: containerID,
                azure_url: azure_url,
            })
            const {BlobServiceClient} = await import('@azure/storage-blob')
            const blobServiceClient = new BlobServiceClient(
                `https://${response.data.account}.blob.core.windows.net?${response.data.token}`,
            )
            const containerClient = blobServiceClient.getContainerClient(containerID)
            const blobClient = containerClient.getBlobClient(azure_url)

            // Download the blob's contents and convert to string
            const downloadBlockBlobResponse = await blobClient.download()
            if (!downloadBlockBlobResponse.readableStreamBody) {
                return ''
            }
            return await streamToString(downloadBlockBlobResponse.readableStreamBody)
        } catch (error: any) {
            const sentryErrors = [StatusCodes.NOT_FOUND, StatusCodes.INTERNAL_SERVER_ERROR]
            if (error.response && error.response.status && sentryErrors.includes(error.response.status)) {
                const name = error.name
                const data = error.response?.data
                const message = error.response?.message
                const {withScope, captureException} = await import('@sentry/nextjs')
                withScope((scope) => {
                    scope.setExtra('error', error)
                    name && scope.setExtra('error.name', name)
                    data && scope.setExtra('error.response.data', {data})
                    message && scope.setExtra('error.response.message', {message})
                    captureException(error)
                })
            }
            return error
        }
    }
}

export async function generateBlobURL(containerID: string, azure_url: string): Promise<string | null | undefined> {
    if (azure_url) {
        try {
            const response = await axios.post('/api/file_download', {
                containerID: containerID,
                azure_url: azure_url,
            })
            const {BlobServiceClient} = await import('@azure/storage-blob')
            const blobServiceClient = new BlobServiceClient(
                `https://${response.data.account}.blob.core.windows.net?${response.data.token}`,
            )
            const containerClient = blobServiceClient.getContainerClient(containerID)
            const blobClient = containerClient.getBlobClient(azure_url)

            const downloadBlockBlobResponse = await blobClient.download()
            const blob = await downloadBlockBlobResponse.blobBody
            if (blob) {
                return blobToBase64(blob)
            }
        } catch (error: any) {
            const sentryErrors = [StatusCodes.NOT_FOUND, StatusCodes.INTERNAL_SERVER_ERROR]
            if (error.response && error.response.status && sentryErrors.includes(error.response.status)) {
                const name = error.name
                const data = error.response?.data
                const message = error.response?.message
                const {withScope, captureException} = await import('@sentry/nextjs')
                withScope((scope) => {
                    scope.setExtra('error', error)
                    name && scope.setExtra('error.name', name)
                    data && scope.setExtra('error.response.data', {data})
                    message && scope.setExtra('error.response.message', {message})
                    captureException(error)
                })
            }
            return error
        }
    }
}

const blobToFile = async (blob: Blob | undefined, fileName: string) => {
    if (blob) {
        saveAs(new Blob([blob]), fileName)
    }
}
