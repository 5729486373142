import useSWR from 'swr'
import {useAuth0} from '@auth0/auth0-react'
import {genevaFetchWithToken} from '@/utils/fetch'
import {getUrlWithParamsString} from '@/utils/general'

export interface GenevaFetchResult {
    error: any
    isLoading: boolean
    data: any
    mutate?: (data?: any, shouldRevalidate?: boolean | undefined) => Promise<any>
}

export default function useGenevaFetch(url: string, options: any = {}, params: any = {}): GenevaFetchResult {
    const urlWithParams = getUrlWithParamsString(url, params)
    const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0()
    const {data, error, mutate} = useSWR(
        isLoading || !isAuthenticated ? null : urlWithParams,
        async (apiUrl) => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
                    scope: 'write:all',
                },
            })
            return await genevaFetchWithToken(apiUrl, accessToken)
        },
        options,
    )
    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    }
}
