import {FC} from 'react'
import {Box, Button, Container, Typography} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import Page from '@/components/Page'

const ServerError: FC = () => {
    const theme = useTheme()
    const mobileDevice = useMediaQuery(theme.breakpoints.down('xl'))

    return (
        <Page title="Server Error">
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    minHeight: '100%',
                    px: 3,
                    py: '80px',
                }}
            >
                <Container maxWidth="lg">
                    <Typography variant={mobileDevice ? 'h4' : 'h1'} sx={{color: 'text.primary', textAlign: 'center'}}>
                        500: Internal Server Error
                    </Typography>
                    <Typography variant="subtitle2" sx={{color: 'text.secondary', mt: 0.5, textAlign: 'center'}}>
                        You either tried some shady route or you came here by mistake. Whichever it is, try using the
                        navigation.
                    </Typography>
                    <Box sx={{display: 'flex', justifyContent: 'center', mt: 6}}>
                        <Box
                            alt="Under development"
                            component="img"
                            src={`/images/error/error500_${theme.palette.mode}.svg`}
                            sx={{height: 'auto', maxWidth: '100%', width: 400}}
                        />
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', mt: 6}}>
                        <Button color="primary" href="/" variant="outlined">
                            Back to Home
                        </Button>
                    </Box>
                </Container>
            </Box>
        </Page>
    )
}

export default ServerError
