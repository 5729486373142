import {FC, ReactNode} from 'react'
import Link from 'next/link'
import {UrlObject} from 'url'

interface Props {
    href: string | UrlObject
    passHref?: boolean
    legacyBehavior?: boolean
    openInNewTab?: boolean
    children: ReactNode
}

const GenevaNextLink: FC<Props> = ({
    href,
    passHref = false,
    legacyBehavior = false,
    openInNewTab = false,
    children,
}) => {
    return (
        <Link
            href={href}
            passHref={passHref}
            style={{textDecoration: 'none', color: 'inherit'}}
            legacyBehavior={legacyBehavior}
            target={openInNewTab ? '_blank' : undefined}
            rel={openInNewTab ? 'noopener' : undefined}
        >
            {children}
        </Link>
    )
}

export default GenevaNextLink
