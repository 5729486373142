import {FC, useRef, useState} from 'react'
import {Badge, IconButton, Tooltip} from '@mui/material'
import FormatListBulletedTwoToneIcon from '@mui/icons-material/FormatListBulletedTwoTone'
import useGenevaUser from '@/hooks/useGenevaUser'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import ServerError from '@/views/error/ServerError'
import dynamic from 'next/dynamic'

const DashboardNavbarTasksPopover = dynamic(
    () => import('@/layouts/ProviderDashboardLayout/DashboardNavbarTasksPopover'),
    {ssr: false},
)

const DashboardNavbarTasks: FC = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const [open, setOpen] = useState<boolean>(false)
    const {genevaApplicationUserId, isGenevaLoading} = useGenevaUser()

    if (isGenevaLoading) {
        return null
    }

    if (!genevaApplicationUserId) {
        return null
    }

    const {data, error, isLoading} = useGenevaFetch(`/api/users/${genevaApplicationUserId}/tasks/due_today_count`)

    if (error) {
        if (!open) {
            return null
        }
        return <ServerError />
    }
    if (isLoading || isGenevaLoading) {
        if (open) {
            return <div>Loading... </div>
        } else {
            return (
                <IconButton color="inherit" size="large">
                    <FormatListBulletedTwoToneIcon fontSize="small" />
                </IconButton>
            )
        }
    }

    const count = !isLoading && data ? data.count : 0

    const handleOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Outstanding Tasks">
                <IconButton color="inherit" ref={anchorRef} onClick={handleOpen} size="large">
                    <Badge color="error" badgeContent={count} max={99}>
                        <FormatListBulletedTwoToneIcon fontSize="small" />
                    </Badge>
                </IconButton>
            </Tooltip>
            {open && <DashboardNavbarTasksPopover anchorRef={anchorRef} open={open} handleClose={handleClose} />}
        </>
    )
}

export default DashboardNavbarTasks
