import authAxios from '@/lib/api/geneva/authAxios'
import {urlWithParams} from '@/lib/api/geneva/util'
import {
    EntityDescriptionInterface,
    InvoiceSearchInvoiceInterface,
    PhoneNumberSearchResultInterface,
    SearchResultInterface,
} from '@/interfaces/search_types'
import {
    PersonAndLeadSearchResultInterface,
    PersonInterface,
    SearchReferralPersonInterface,
} from '@/interfaces/person_types'
import {SupplierInterface} from '@/interfaces/supplier_types'
import {PricingConfigurationInterface} from '@/interfaces/pricing_types'
import {PostcodeHeaderInterface} from '@/interfaces/geography_types'

interface SearchEntityDescriptionResult {
    status: number
    data: {
        message: string
        results: EntityDescriptionInterface[]
        count: number
    }
}

interface SearchResult {
    status: number
    data: {
        message: string
        results: SearchResultInterface[]
        count: number
    }
}

interface PhoneNumberSearchResult {
    status: number
    data: {
        message: string
        results: PhoneNumberSearchResultInterface[]
        count: number
    }
}

export async function searchEntityDescription(input: string, abort: AbortController): Promise<SearchResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/entity_description', params), {
        signal: abort.signal,
    })
    return {data, status}
}

export async function searchPhoneNumber(input: string, abort: AbortController): Promise<PhoneNumberSearchResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/phone_number', params), {
        signal: abort.signal,
    })
    return {data, status}
}

export async function searchPersonDescription(input: string): Promise<SearchEntityDescriptionResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/person_description', params))
    return {data, status}
}

export async function searchPersonDescriptionForSupplierId(
    supplier_id: number,
    input: string,
): Promise<SearchEntityDescriptionResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(
        urlWithParams(`/api/supplier_portal/suppliers/${supplier_id}/clients/search`, params),
    )
    return {data, status}
}

interface SearchPersonResult {
    status: number
    data: {
        message: string
        results: PersonInterface[]
        count: number
    }
}

export async function searchPerson(input: string): Promise<SearchPersonResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/persons', params))
    return {data, status}
}

export async function searchPersonWithExclusion(input: string, person_id: number): Promise<SearchPersonResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams(`/api/search/persons_with_exclusion/${person_id}`, params))
    return {data, status}
}

export async function searchClients(input: string): Promise<SearchPersonResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/clients', params))
    return {data, status}
}

export async function searchOpenClients(input: string): Promise<SearchPersonResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/clients/open', params))
    return {data, status}
}

export async function searchActiveClients(input: string): Promise<SearchPersonResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/clients/active', params))
    return {data, status}
}

interface SearchReferralPersonResult {
    status: number
    data: {
        message: string
        results: SearchReferralPersonInterface[]
        count: number
    }
}

export async function searchReferralPerson(input: string): Promise<SearchReferralPersonResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/referral_person', params))
    return {data, status}
}

interface SearchPersonAndLeadResult {
    status: number
    data: {
        message: string
        results: PersonAndLeadSearchResultInterface[]
        count: number
    }
}

export async function searchPersonAndLead(input: string): Promise<SearchPersonAndLeadResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/person_and_lead', params))
    return {data, status}
}

interface SearchSupplierResult {
    status: number
    data: {
        message: string
        results: SupplierInterface[]
        count: number
    }
}

export async function searchSupplier(input: string): Promise<SearchSupplierResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/suppliers', params))
    return {data, status}
}

interface SupplierPostcodeSearchResult {
    status: number
    data: {
        message: string
        postcode_data: PostcodeHeaderInterface
        results: PricingConfigurationInterface[]
        count: number
    }
}

interface SupplierPostcodeSearchRequest {
    postcode: string
    service_ids?: number[]
}

export async function supplierPostcodeSearch(
    postcode: string,
    serviceIds: number[],
): Promise<SupplierPostcodeSearchResult> {
    let params: SupplierPostcodeSearchRequest = {postcode: postcode}
    if (serviceIds.length > 0) {
        params = {...params, service_ids: serviceIds}
    }
    const {data, status} = await authAxios.post('/api/search/supplier_postcode_search', params)
    return {data, status}
}

interface InvoiceSearchResult {
    status: number
    data: {
        message: string
        results: InvoiceSearchInvoiceInterface[]
        count: number
    }
}

export async function searchInvoices(input: string): Promise<InvoiceSearchResult> {
    const params = input && input.length > 0 ? {q: input} : {}
    const {data, status} = await authAxios.get(urlWithParams('/api/search/invoice_search', params))
    return {data, status}
}
