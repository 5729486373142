import {FC, ReactNode, useEffect, useState} from 'react'
import {styled} from '@mui/material/styles'
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'
import {SectionInterface} from '@/interfaces/layout_types'
import useGenevaUser from '@/hooks/useGenevaUser'
import {
    topAdminSection,
    topBookingsSection,
    topFinanceSection,
    topMarketingSection,
    topMyClientsSection,
    topProviderSection,
    topSalesSection,
    topSupplierSection,
} from '@/config/navbarSections'

// overflows were disabled to allow for sticky headers, such as in assessments
// maxWidth was set to 100% as some pages would horizontally scroll with overflows disabled

const DashboardLayoutRoot = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    // overflow: 'hidden',
    maxWidth: '100%',
    width: '100%',
}))

const DashboardLayoutWrapper = styled('div', {
    shouldForwardProp: (propName) => propName == 'dashboardnavbarrootheight' || propName == 'children',
})<{
    dashboardnavbarrootheight: number
}>(({theme, dashboardnavbarrootheight}) => ({
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden',
    paddingTop: dashboardnavbarrootheight,
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px',
    },
}))

const DashboardLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden',
    maxWidth: '100%',
})

const DashboardLayoutContent = styled('div')({
    flex: '1 1 auto',
    height: '100%',
    // overflow: 'auto',
    position: 'relative',
    WebkitOverflowScrolling: 'touch',
    maxWidth: '100%',
})

interface DashboardLayoutProps {
    children?: ReactNode
    sections: SectionInterface[]
}

const DashboardLayout: FC<DashboardLayoutProps> = ({children, sections}) => {
    const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false)
    const [dashboardNavbarRootHeight, setDashboardNavbarRootHeight] = useState<number>(64)

    const {genevaUser, isGenevaLoading} = useGenevaUser()

    useEffect(() => {
        const dashboardNavbarRoot = document.getElementById('dashboardNavbarRoot')
        if (dashboardNavbarRoot) {
            setDashboardNavbarRootHeight(dashboardNavbarRoot.clientHeight)
        }
    }, [])

    window.addEventListener('resize', () => {
        const dashboardNavbarRoot = document.getElementById('dashboardNavbarRoot')
        if (dashboardNavbarRoot) {
            setDashboardNavbarRootHeight(dashboardNavbarRoot.clientHeight)
        }
    })

    const isUserProviderAdmin = !isGenevaLoading && genevaUser && genevaUser.is_provider_admin
    const isUserFinance = !isGenevaLoading && genevaUser && genevaUser.is_finance
    const isUserSalesAdmin =
        !isGenevaLoading && genevaUser && (genevaUser.is_manager_sales || genevaUser.is_provider_admin)
    const isUserBookings =
        !isGenevaLoading &&
        genevaUser &&
        (genevaUser.is_concierge ||
            genevaUser.is_service_coordinator ||
            genevaUser.is_manager ||
            genevaUser.is_provider_admin ||
            genevaUser.is_service_coordinator_team_leader)

    let topItems = []
    if (isUserProviderAdmin) {
        topItems.push(topAdminSection())
        topItems.push(topFinanceSection())
    } else if (isUserFinance) {
        topItems.push(topFinanceSection())
    }
    topItems.push(topProviderSection())
    topItems = topItems.concat(topMyClientsSection(genevaUser), topSupplierSection(), topSalesSection(genevaUser))
    if (isUserSalesAdmin) {
        topItems.push(topMarketingSection())
    }
    if (isUserBookings) {
        topItems.push(topBookingsSection(genevaUser))
    }

    let topSections = [
        {
            title: 'Irys',
            items: topItems,
        },
    ]

    const allSections = topSections.concat(sections)

    return (
        <DashboardLayoutRoot>
            <DashboardNavbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
            <DashboardSidebar
                onMobileClose={(): void => setIsSidebarMobileOpen(false)}
                openMobile={isSidebarMobileOpen}
                sections={allSections}
                dashboardNavbarRootHeight={dashboardNavbarRootHeight}
            />
            <DashboardLayoutWrapper dashboardnavbarrootheight={dashboardNavbarRootHeight}>
                <DashboardLayoutContainer>
                    <DashboardLayoutContent>
                        <>{children}</>
                    </DashboardLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    )
}

export default DashboardLayout
