import {FC, useRef, useState} from 'react'
import {Box, ButtonBase} from '@mui/material'
import useGenevaUser from '@/hooks/useGenevaUser'
import UserAvatar from '@/components/user/UserAvatar'
import dynamic from 'next/dynamic'

const DashboardNavbarAccountPopover = dynamic(
    () => import('@/layouts/ProviderDashboardLayout/DashboardNavbarAccountPopover'),
    {ssr: false},
)

const DashboardNavbarAccount: FC = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const {genevaUser} = useGenevaUser()
    const [open, setOpen] = useState<boolean>(false)

    if (!genevaUser) {
        return null
    }

    const handleOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpen}
                ref={anchorRef}
                sx={{alignItems: 'center', display: 'flex'}}
            >
                <UserAvatar application_user_id={genevaUser.id} />
            </Box>
            {open && <DashboardNavbarAccountPopover anchorRef={anchorRef} open={open} handleClose={handleClose} />}
        </>
    )
}

export default DashboardNavbarAccount
