import {FC, useRef, useState} from 'react'
import {Alert, Box, Typography} from '@mui/material'
import useGenevaUser from '@/hooks/useGenevaUser'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import {getAlertSeverity, WarningInterface} from '@/components/widgets/Layout/WarningAlerts'
import ServerError from '@/views/error/ServerError'
import dynamic from 'next/dynamic'

const DashboardNavbarUserWarningPopoverInterface = dynamic(
    () => import('@/layouts/ProviderDashboardLayout/DashboardNavbarUserWarningPopover'),
    {ssr: false},
)

const DashboardNavbarUserWarning: FC = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const [open, setOpen] = useState<boolean>(false)

    const {data, isLoading, error} = useGenevaFetch('/api/users/warning_controller')

    const {isGenevaLoading} = useGenevaUser()

    if (isGenevaLoading) {
        return null
    }

    if (error) {
        return <ServerError />
    }

    const warnings = !isLoading ? (data.warnings as WarningInterface[]) : []

    const handleOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    return warnings.length > 0 ? (
        <>
            <Alert
                severity={getAlertSeverity(warnings[0])}
                onClick={handleOpen}
                sx={{cursor: 'pointer', alignItems: 'center', py: 0}}
            >
                <Box ref={anchorRef} sx={{ml: 1, mr: 1}}>
                    <Typography>{`Warnings (${warnings.length})`}</Typography>
                </Box>
            </Alert>
            {open && (
                <DashboardNavbarUserWarningPopoverInterface
                    warnings={warnings}
                    anchorRef={anchorRef}
                    open={open}
                    handleClose={handleClose}
                />
            )}
        </>
    ) : null
}

export default DashboardNavbarUserWarning
