import {FC, useRef, useState} from 'react'
import {Badge, CircularProgress, IconButton, Tooltip} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import useGenevaUser from '@/hooks/useGenevaUser'
import ServerError from '@/views/error/ServerError'
import dynamic from 'next/dynamic'

const DashboardNavbarNotificationsPopover = dynamic(
    () => import('@/layouts/ProviderDashboardLayout/DashboardNavbarNotificationsPopover'),
    {ssr: false},
)

const DashboardNavbarNotifications: FC = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const [open, setOpen] = useState<boolean>(false)
    const {genevaApplicationUserId, isGenevaLoading} = useGenevaUser()

    if (isGenevaLoading) {
        return null
    }

    const {data, error, isLoading} = useGenevaFetch(`/api/users/${genevaApplicationUserId}/notifications/unread/count`)

    if (error) {
        if (!open) {
            return null
        }
        return <ServerError />
    }

    if (isLoading) {
        if (open) {
            return <CircularProgress />
        } else {
            return (
                <IconButton color="inherit" size="large">
                    <NotificationsIcon fontSize="small" />
                </IconButton>
            )
        }
    }

    const count = !isLoading && data ? data.count : 0

    const handleOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton color="inherit" ref={anchorRef} onClick={handleOpen} size="large">
                    <Badge color="error" badgeContent={count}>
                        <NotificationsIcon fontSize="small" />
                    </Badge>
                </IconButton>
            </Tooltip>
            {open && (
                <DashboardNavbarNotificationsPopover anchorRef={anchorRef} open={open} handleClose={handleClose} />
            )}
        </>
    )
}

export default DashboardNavbarNotifications
